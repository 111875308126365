/*
  this, in combination with the videojs vjs-16-9 allows for the player to keep
  its aspect ratio, and scale to the width/height of the browser viewport
  (whichever is smallest)

  should figure out a better way than using !important overrides, but the
  fluid selectors are already pretty specific so its hard to do so reliably
*/
.video-js {
  height: 100vh !important;
  padding: 0 !important
}

.video-js .vjs-play-control, .video-js .vjs-big-play-button {
  display: none;
}

.video-js.vjs-playing .vjs-tech {
  pointer-events: none;
}
