/**
 * Semantic UI has these classes, however they're only applicable to
 * grids, containers, rows and columns.
 * plus, there isn't any `mobile hidden`, `X hidden` class.
 * this snippet is using the same class names and same approach
 * plus a bit more but to all elements.
 *
 * see https://github.com/Semantic-Org/Semantic-UI/issues/1114
 */

/* Mobile */
@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="large screen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}

.hidden.menu {
  display: none;
}

.masthead.segment {
  min-height: 70vh;
  padding: 1em 0;
}

.masthead .logo.item img {
  margin-right: 1em;
}

.masthead .ui.menu .ui.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  margin-bottom: 0;
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

@media only screen and (max-width: 959px) {
  .ui.vertical.stripe {
    padding: 1.5em 0;
  }
}

@media only screen and (min-width: 960px) {
  .ui.vertical.stripe {
    padding: 3em 0 1.5em;
  }
}

.ui.vertical.stripe h3 {
  font-size: 2em;
}

.ui.vertical.stripe p + h3,
.ui.vertical.stripe .button + h3 {
  margin-top: 3em;
}

.ui.vertical.stripe .floated.image {
  clear: both;
}

.ui.vertical.stripe p {
  font-size: 1.33em;
}

.ui.vertical.stripe .horizontal.divider {
  margin: 3em 0;
}

.ui.stripe.alternate {
  background-color: #f2f3f5;
}

.quote.stripe.segment {
  padding: 0;
}

.quote.stripe.segment .grid .column {
  padding-top: 5em;
  padding-bottom: 5em;
}

.footer.segment {
  padding: 5em 0;
}

.secondary.pointing.menu .toc.item {
  display: none;
}

.ui.inverted.placeholder,
.ui.inverted.placeholder > :before,
.ui.inverted.placeholder .image.header:after,
.ui.inverted.placeholder .line,
.ui.inverted.placeholder .line:after {
  background-color: #000000 !important;
}

.ui.secondary.menu footer a {
  color: white;
  font-weight: bold;
}

.ui.secondary.menu footer a:hover {
  color: #CCC;
}

@media only screen and (max-width: 700px) {
  .masthead.segment {
    min-height: 65vh;
  }

  .masthead h1.ui.header {
    font-size: 2em;
  }

  .masthead h4 {
    margin-top: 0.5em;
    font-size: 1.5em;
  }
}

/* Code blocks (Fix a strange bug with prismjs) */
pre {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
  background: #272822;
}

.ui.inverted.segment,
.ui.primary.inverted.segment {
  background: #0f171e;
}

@media only screen and (max-width: 767px) {
  #navbar {
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
}

@media only screen and (min-width: 768px) {
  #similar {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .ui.grid > [class*="five wide mobile"].column {
    padding-left: 0em;
  }
}

@font-face {
  font-family: Poppins Bold;
  src: url(../common/Poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins Light;
  src: url(../common/Poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: Poppins Extra Light;
  src: url(../common/Poppins/Poppins-ExtraLight.ttf);
}
