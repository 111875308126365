/*******************************
         Site Overrides
*******************************/

.ui.button.show-more {
    background-color: @white !important;
}
.ui.button.show-more:hover {
    background-color: #E9E9E9 !important;
}
